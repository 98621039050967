import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { themeStyles, colors, presets } from '../utils/theme'
import { chunk, range } from 'lodash'
import Content, { HTMLContent } from '../components/Content'
import Carousel from 'nuka-carousel'
import { FaStar, FaChevronRight, FaChevronLeft } from 'react-icons/fa';


const styles = {
  featuredImage: {
    maxWidth: '200px',
    width: '100%',
  },
  container: {
    width: '30vw',
    maxWidth: '350px',
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
  name: {
    color: colors.blueGreen,
  },
  title: {
    marginTop: '10px',
    color: colors.mediumGrey,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  reviewRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '40px',
  },
  blankButton: {
    backgroundColor: 'transparent',
    border: 0,
  },
  starHolder: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    paddingLeft: '45px',
  },
  attribution: {
    paddingLeft: '45px',
    marginTop: '20px',
  },
  reviewContainer: {
    paddingLeft: '45px',
    paddingRight: '45px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'stretch',
    borderLeft: 0,
    [presets.Tablet]: {
      borderLeft: `1px solid ${colors.lightGrey}`,
    }
  },
  lastReviewContainer: {
    borderRight: 0,
    [presets.Tablet]: {
      borderRight: `1px solid ${colors.lightGrey}`,
    }
  },
  center: {
    textAlign: 'center',
  },

  tabletOnly: {
    display: 'block',
    [presets.Tablet]: {
      display: 'none',
    },
  },
  desktopOnly: {
    display: 'none',
    [presets.Tablet]: {
      display: 'block',
    },
    [presets.Desktop]: {
      display: 'none',
    }
  },
  desktopPlus: {
    display: 'none',
    [presets.Desktop]: {
      display: 'block',
    }
  }
}

class ReviewRoll extends React.Component {

  getPostsCarousel = (posts, numberToDisplayInRow=3, style=styles.desktopPlus ) => {
    const PostContent = this.props.contentComponent || Content
    const postChunks = chunk(posts || [], numberToDisplayInRow)

    const postsMarkup = postChunks.map((chunkInstance, chunkInstanceIndex) => {
      const result = chunkInstance.map(({ node: post }, index) =>
        (<div key={post.id}>
          <div css={styles.container}>
            <div css={styles.starHolder}>
              {range(5).map(num => <div key={num}><FaStar size={25} color={ num <= post.frontmatter.numberOfStars ? colors.green : colors.lightGrey}/></div>)}
            </div>
            <div css={[styles.reviewContainer, index === chunkInstance.length - 1 ? styles.lastReviewContainer : {} ]}>
              <PostContent content={post.htmlAst} />
            </div>
            <p css={styles.attribution}>
              <span css={{ fontWeight: 500 }}>{post.frontmatter.customerName}</span>
              <br/>
              {post.frontmatter.customerFrom}
            </p>
          </div>
        </div>
      ))
      return (
        <div key={chunkInstanceIndex} css={styles.reviewRow}>
          {result}
        </div>
      )
    })

    return (
      <div css={style} key={numberToDisplayInRow}>
        <Carousel
          heightMode='current'
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide} css={[styles.blankButton, { display: 'none', [presets.Mobile]: {display: 'block', position: 'absolute', left: '-.9rem', cursor: 'pointer'} }]} ><FaChevronLeft size={45} color={colors.green} /></button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide} css={[styles.blankButton, { display: 'none', [presets.Mobile]: {display: 'block', position: 'absolute', right: '-.9rem', cursor: 'pointer'} }]} ><FaChevronRight size={45} color={colors.green} /></button>
          )}
        >
          {postsMarkup}
        </Carousel>
      </div>
    )
  }

  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    // hacky, but a quick and dirty way to make this mobile friendly
    // use css breakpoints to only display one of these at a time
    // state won't be the same for each, but only person who would care is a QA tester
    const postMarkup3 = this.getPostsCarousel(posts, 3, styles.desktopPlus)
    const postMarkup2 = this.getPostsCarousel(posts, 2, styles.desktopOnly)
    const postMarkup1 = this.getPostsCarousel(posts, 1, styles.tabletOnly)

    return (
      <div>
        <h2 css={[themeStyles.sectionHeader, styles.center]}>
          See what our customers think
        </h2>
        <div css={{...themeStyles.contentWidth, maxWidth: '95vw', padding: '0 5px'}}>
          {postMarkup1}
          {postMarkup2}
          {postMarkup3}
        </div>
      </div>
    )
  }
}

ReviewRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ReviewRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "customer-review" } } }
          limit: 9
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              htmlAst
              fields {
                slug
              }
              frontmatter {
                templateKey
                customerName
                customerFrom
                reviewPlatform
                numberOfStars
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ReviewRoll contentComponent={HTMLContent} data={data} count={count} />}
  />
)
